import React from "react";
import "./Marquee.style.css";

const marquee = () => {
  return <span className="marqueeParent"></span>;
    

};

export default marquee;
