export const HospitalNavLinks = [
  {
    id: "hospitalService",
    title: "hospital service",
  },
  {
    id: "hospital-staff",
    title: "hospital staff",
  },
  {
    id: "ipd",
    title: "ipd",
  },
  {
    id: "opd",
    title: "opd",
  },
  {
    id: "canteen-room",
    title: "canteen room",
  },
  {
    id: "pharmacy",
    title: "pharmacy",
  },
];
