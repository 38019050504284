export const HospitalStaffMembers = [
  {
    Name: "Dr. Asim Kumar Manna",
    Photo: require("../assets/HospitalStaff/h_2.jpeg"),
    GuardianName: "Dasarati Manna",
    Qualification: "D.M.S.",
    DOB: "12/14/1964",
    Address: "",
    AcademicOrganization: "Council of Hom. Medicine, W.B.",
    Designation: "Senior Medicial Officer",
    Appointment: "08/26/1997",
    Department: "Hospital",
    Contact: "9434978811",
  },
  {
    Name: "Dr. Sima Mondal",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "Dr. Haripada Mondal",
    Qualification: "D.M.S.",
    DOB: "01/02/1959",
    Address: "2, Naba Basu Lane. K.G.Bose Sarani. Kolkata 85",
    AcademicOrganization: "Council of Hom. Medicine, W.B.",
    Designation: "Medical Officer, Retired - Jan 2024",
    Appointment: "01.06.2001",
    Department: "Hospital",
    Contact: "9830159007",
  },
  {
    Name: "Dr. Ajit Kumar Paul",
    Photo: require("../assets/HospitalStaff/h_4.jpeg"),
    GuardianName: "Jitendra Lal Pal",
    Qualification: "D.M.S.",
    DOB: "04.01.1960",
    Address: "15/45, Narkeldanga North Road. Kolkata  700 011",
    AcademicOrganization: "Council of Hom. Medicine, W.B.",
    Designation: "Medical Officer",
    Appointment: "11/23/2011",
    Department: "Hospital",
    Contact: "9123739886",
  },
  {
    Name: "Dr. Dipankar Naskar",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "B.H.M.S.",
    DOB: "17.09.1995",
    Address: "",
    AcademicOrganization: "WBUHS,  Reg.No 34062",
    Designation: "Medical Officer",
    Appointment: "24/5/2024",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Rajen Das",
    Photo: require("../assets/HospitalStaff/h_5.png"),
    GuardianName: "Ganesh Das",
    Qualification: "VI Passed",
    DOB: "01.12.1971",
    Address: "35, Narkeldanga North Road. Kolkata  11",
    AcademicOrganization: "",
    Designation: "Sweeper",
    Appointment: "07.12.05",
    Department: "Hospital",
    Contact: "9330348941",
  },
  {
    Name: "Chandrika Das",
    Photo: require("../assets/HospitalStaff/h_6.png"),
    GuardianName: "Lachman Das",
    Qualification: "Literate",
    DOB: "12/20/1975",
    Address: "Kakurja  D, Naitand, Big Post � Suria, Gridi, Jharkhand � 825320",
    AcademicOrganization: "",
    Designation: "Sweeper",
    Appointment: "",
    Department: "Hospital",
    Contact: "8420324259",
  },
  {
    Name: "Manick Ghosh",
    Photo: require("../assets/HospitalStaff/h_7.png"),
    GuardianName: "Panchu Gopal Ghosh",
    Qualification: "S. F.",
    DOB: "10/08/1977",
    Address: "1/10, A. B. Bose Lane. Kolkata  11",
    AcademicOrganization: "West Bengal Board of Secondary Education",
    Designation: "Registration Clerk\n (Ticket Collector)",
    Appointment: "23.11.11",
    Department: "Hospital",
    Contact: "9231398653",
  },
  {
    Name: "Manika Ghosh",
    Photo: require("../assets/HospitalStaff/h_8.jpg"),
    GuardianName: "Late Lakshi Kanta Ghosh",
    Qualification: "X Passed",
    DOB: "01/10/1972",
    Address: "22/1, Motilal Sen Lane. Kolkata  11",
    AcademicOrganization: "",
    Designation: "Asstt. Nurse",
    Appointment: "23.11.11",
    Department: "Hospital",
    Contact: "9830778455",
  },
  {
    Name: "Kalipada Das",
    Photo: require("../assets/HospitalStaff/h_9.jpg"),
    GuardianName: "Late Sambhu Das",
    Qualification: "VIII Passed",
    DOB: "13.10.90",
    Address: "41D/108, Murari Pukur Road. Kolkata \n 700 067",
    AcademicOrganization: "",
    Designation: "X-Ray & Darkroom Attendant",
    Appointment: "09.07.14",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Rajesh Prasad",
    Photo: require("../assets/HospitalStaff/h_10.png"),
    GuardianName: "Late Girja Prasad",
    Qualification: "VIII Passed",
    DOB: "07/12/1974",
    Address: "41/6, Mahesh Barik Lane. Kolkata  11",
    AcademicOrganization: "",
    Designation: "Sweeper",
    Appointment: "23.11.11",
    Department: "Hospital",
    Contact: "8240533904",
  },
  {
    Name: "Sanjay Prajapati",
    Photo: require("../assets/HospitalStaff/h_11.png"),
    GuardianName: "Piyarelal Prajapati",
    Qualification: "VIII Passed",
    DOB: "03/12/1989",
    Address: "245/A, Manicktala Main Road. Kolkata  54",
    AcademicOrganization: "",
    Designation: "Sweeper",
    Appointment: "23.11.11",
    Department: "Hospital",
    Contact: "6289327060",
  },
  {
    Name: "Rekha Pachal",
    Photo: require("../assets/HospitalStaff/h_12.jpg"),
    GuardianName: "Dilip Pachal",
    Qualification: "",
    DOB: "04/06/1978",
    Address: "110/H/3, Narkeldanga North Road. Kolkata  11",
    AcademicOrganization: "",
    Designation: "Aaya",
    Appointment: "23.11.11",
    Department: "Hospital",
    Contact: "6291730764",
  },
  {
    Name: "Nila Dhar (Mukherjee)",
    Photo: require("../assets/HospitalStaff/h_13.jpg"),
    GuardianName: "Pradip Dhar",
    Qualification: "Madhyamick",
    DOB: "04/05/1975",
    Address: "39/9, Manicktala Main Road. Kolkata  54",
    AcademicOrganization: "WBBSE",
    Designation: "Asstt. Nurse",
    Appointment: "10.05.12",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Riya Ghosh",
    Photo: require("../assets/HospitalStaff/h_14.jpg"),
    GuardianName: "Biswajit Ghosh",
    Qualification: "H.S.Passed",
    DOB: "08/02/1987",
    Address: "11/A, Girish Bidya Ratna Lane. Kolkata  11",
    AcademicOrganization: "WBCHSE",
    Designation: "Asstt. Nurse",
    Appointment: "07.05.12",
    Department: "Hospital",
    Contact: "8017271647",
  },
  {
    Name: "Sanchaita Gupta",
    Photo: require("../assets/HospitalStaff/h_15.jpg"),
    GuardianName: "Shyamal Gupta",
    Qualification: "Madhyamick",
    DOB: "03.09.81",
    Address: "3/1/74/1, Beliaghata Main Road. Kolkata 85",
    AcademicOrganization: "WBBSE",
    Designation: "Asstt. Nurse",
    Appointment: "02.01.14",
    Department: "Hospital",
    Contact: "8017515926",
  },
  {
    Name: "Jannatun Nesha",
    Photo: require("../assets/HospitalStaff/h_16.jpg"),
    GuardianName: "",
    Qualification: "D.N.M.A",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "Asstt. Nurse",
    Appointment: "07.02.20",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Prosenjit Bhowmik",
    Photo: require("../assets/HospitalStaff/h_17.jpg"),
    GuardianName: "Shanto Bhowmick",
    Qualification: "Madhyamick",
    DOB: "07/25/1991",
    Address: "224A/H/5/A, Manicktala Main Road. Kolkata  54",
    AcademicOrganization: "WBBSE",
    Designation: "Ward Boy",
    Appointment: "02.01.14",
    Department: "Hospital",
    Contact: "9051129864",
  },
  {
    Name: "Maneka Das",
    Photo: require("../assets/HospitalStaff/h_18.png"),
    GuardianName: "Shyamal Das",
    Qualification: "Appeared X",
    DOB: "15.05.82",
    Address: "15/H/4/1, Narkeldanga North Road. Kolkata  11",
    AcademicOrganization: "",
    Designation: "Aya",
    Appointment: "02.01.14",
    Department: "Hospital",
    Contact: "8981750704",
  },
  {
    Name: "Jaydeb Das",
    Photo: require("../assets/HospitalStaff/h_19.jpg"),
    GuardianName: "Lakshmi Kanta Das",
    Qualification: "Madhyamick",
    DOB: "24.12.79",
    Address: "223/1/3, Manicktala Main Road. Kolkata  54",
    AcademicOrganization: "WBBSE",
    Designation: "Ward Boy",
    Appointment: "02.01.14",
    Department: "Hospital",
    Contact: "9038927407",
  },
  {
    Name: "Tanmoy Barik",
    Photo: require("../assets/HospitalStaff/h_20.jpg"),
    GuardianName: "Tapan Barik",
    Qualification: "Madhyamick",
    DOB: "10.09.91",
    Address: "119B, Narkeldanga North Road.\n Kolkata  11",
    AcademicOrganization: "WBBSE",
    Designation: "Dresser",
    Appointment: "02.01.14",
    Department: "Hospital",
    Contact: "798095626",
  },
  {
    Name: "Mr. Subham Bakuli",
    Photo: require("../assets/HospitalStaff/h_21.png"),
    GuardianName: "Sushanta Kumar Bakuli",
    Qualification: "B.A.",
    DOB: "01/15/1993",
    Address: "118/2, Narkeldanga North Road. Kolkata  700 011",
    AcademicOrganization: "Calcutta University",
    Designation: "Dispenser & Data Operator",
    Appointment: "01.10.20",
    Department: "Hospital",
    Contact: "9123007350",
  },
  {
    Name: "Dr. Subhasish Saha",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "MBBS, \n MD - Patho",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "Pathologist & Consultant",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Dr. Soumik Sen",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "BHMS",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "House Physician",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Dr. Shovanlal Mondal",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "BHMS",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "House Physician",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Dr. Taufique Sk.",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "BHMS",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "House Physician",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Dr. Sajid Akram",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "BHMS",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "House Physician",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Dr. Sadique Fakhruddin",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "BHMS",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "House Physician",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Mr. B.S.Choudhury",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "H.S.",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "Lab Tech.",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Mr. Asit Bose",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "Madhyamick",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "X-Ray Tech. & Radiographer",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Mrs. Jyoti Verma",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "MA, Master in YOGA",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "YOGA Expert",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Dr. Sabir Ahmed",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "DMS",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "Physical Medicine",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Dr. D. Roy",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "MD (Hom.), \n M.Sc � Nutrition",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "Dietitian",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Mr. Surajit Mondal",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "B. Com",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "Account Clerk",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Mr. S. K. Sinha chowdhury",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "C.A.",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "Auditor",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Mrs. Susmita Das",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "E.C.G. Trained",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Mr. Sharvan Rajak",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "VIII Passed",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "Rajak (Dhopa)",
    Appointment: "",
    Department: "Hospital",
    Contact: "",
  },
  {
    Name: "Mr. Avijit Marik",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "Madhyamick",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "Electrician",
    Appointment: "",
    Department: "Hospital & College",
    Contact: "",
  },
  {
    Name: "Mr. Manas Das",
    Photo: require("../assets/avatar.jpg"),
    GuardianName: "",
    Qualification: "Madhyamick",
    DOB: "",
    Address: "",
    AcademicOrganization: "",
    Designation: "Canteen Manager",
    Appointment: "",
    Department: "-do-",
    Contact: "",
  },
];

export const HospitalStaffKey = [
  { key: "Name", label: "Name of the Employee" },
  { key: "GuardianName", label: "Father / Husband's Name" },
  { key: "Qualification", label: "Qualification" },
  { key: "DOB", label: "Date of Birth" },
  { key: "Address", label: "Residential Address" },
  {
    key: "AcademicOrganization",
    label: "Passing Academic Organization /University",
  },
  { key: "Designation", label: "Designation" },
  { key: "Appointment", label: "Joining of Appointment" },
  { key: "Department", label: "Department" },
  { key: "Contact", label: "Contact /Remarks" },
];
